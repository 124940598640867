import type { NextRouter } from "next/router";

export const LESSON_DASHBOARD_MAX_WIDTH = 1200;

const umcBreadcrumbStyles = {
  breadcrumbBar: {
    maxWidth: LESSON_DASHBOARD_MAX_WIDTH,
    width: "100%",
    mx: "auto",
    px: 4,
    pb: 3,
  },
  breadcrumbs: {
    paddingLeft: 0,
  },
  pupilPlatformButton: {
    marginRight: 0,
  },
};

export const getUmcLayoutStyles = (
  router: NextRouter,
  umcLessonDashboardEnabled: boolean,
  styleField: keyof typeof umcBreadcrumbStyles,
) => {
  if (!router.query["learning-unit"] || !umcLessonDashboardEnabled) return {};
  return umcBreadcrumbStyles[styleField];
};
